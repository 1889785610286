import { ResponsiveValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

import card from '@assets/card.png';
import laptop from '@assets/laptop.png';
import Mentor from '@assets/mentorimage.png';
import personalData from '@assets/personalData.svg';
import phone from '@assets/phone.png';
import sofa from '@assets/sofa.svg';

dayjs.extend(utc);
dayjs.extend(timezone);

export function changeTimeStringToDateFormat(hour: number) {
    const baseDate = dayjs('2024-01-22').utc().hour(hour).minute(0).second(0).tz('Asia/Kolkata');
    return baseDate.toISOString();
}

export const categoriesData =
    'SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBERSECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY';

export const membersNewData = [
    {
        name: 'Floyd Miles',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
    {
        name: 'Albert Flores',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
    {
        name: 'Ronald Richards',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
    {
        name: 'Jenny Wilon',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
];

export const partnerNewCardData = [
    {
        heading: 'Our Beloved Partners',
        text: 'Lorem ipsum dolor sit amet, consectetur a dipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        backgroundImage: 'rgba(184, 208, 230, 1)',
        imageSrc: sofa,
    },
    {
        heading: 'Our Beloved Partners',
        text: 'Lorem ipsum dolor sit amet, consectetur a dipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        backgroundImage: 'rgba(242, 199, 212, 1)',
        imageSrc: laptop,
    },
    {
        heading: 'Our Beloved Partners',
        text: 'Lorem ipsum dolor sit amet, consectetur a dipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        backgroundImage: 'rgba(178, 160, 222, 1)',
        imageSrc: phone,
    },
];

export const textsData = [
    'Decision-overwhelm and analysis-paralysis',
    'Lack of like-minded peers in your circle',
    'Expensive consultants & coaches',
];
export const newTextsData = [
    'Not enough time to do “all the thing',
    'Too much reliance on trial and error',
    'Content overload & noise',
];
export const newStepsData = [
    {
        stepNumber: '1',
        heading: 'Login or Register',
        text: 'Create a personalized account or log in to unlock access to a world of mentorship opportunities.',
        imageSrc: Mentor,
        backgroundImage: '#F2C7D4',
        direction: { base: 'column', md: 'row' },
        bullets: [
            'Build your profile as a foundation for tailored guidance.',
            'Track your progress, explore features, and manage your sessions seamlessly.',
            'Join a dynamic community of learners and mentors.',
        ],
    },
    {
        stepNumber: '2',
        heading: 'Fill Your Personal Data',
        text: 'Provide detailed information to customize your experience and achieve your goals.',
        imageSrc: personalData,
        backgroundImage: '#0F98AB',
        direction: { base: 'column', md: 'row-reverse' },
        bullets: [
            'Highlight your skills, aspirations, and areas of focus.',
            'Enable mentors to offer the most relevant guidance and support.',
            'Create a robust profile that reflects your professional and personal interests.',
        ],
    },
    {
        stepNumber: '3',
        heading: 'Find the Mentor',
        text: 'Explore a diverse range of mentors to connect with the right expert for your journey.',
        imageSrc: card,
        backgroundImage: '#B2A0DE',
        direction: { base: 'column', md: 'row' },
        bullets: [
            'Use filters to search by expertise, industry, or specific goals.',
            'Review mentor profiles, feedback, and availability.',
            'Select a mentor who resonates with your vision and objectives.',
        ],
    },
    {
        stepNumber: '4',
        heading: 'Start the Sessions',
        text: 'Book your first session to kickstart a collaborative and productive mentorship experience.',
        imageSrc: Mentor,
        backgroundImage: '#E5E7EB',
        direction: { base: 'column', md: 'row-reverse' },
        bullets: [
            'Schedule sessions at your convenience for a smooth start.',
            'Engage in personalized, actionable discussions with your mentor.',
            'Leverage insights to accelerate your growth and achieve measurable outcomes.',
        ],
    },
] as {
    stepNumber: string;
    heading: string;
    text: string;
    imageSrc: string | StaticImport;
    backgroundImage: string;
    direction: ResponsiveValue<'row' | 'column' | 'row-reverse' | 'column-reverse'> | undefined;
    bullets: Array<string>;
}[];

import React from 'react';

import { Divider, Flex, useBreakpointValue } from '@chakra-ui/react';

import mentees from '@assets/mentees.svg';
import sessionsBooked from '@assets/sessionsBooked.svg';
import wallet from '@assets/wallet.svg';

import { MenterInfoCard } from '../cards/MenterInfoCard';

export const MentorIcon = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Flex
            my={{ base: 2, md: 20 }}
            mx={{ base: 0, md: -10, lg: -20 }}
            bg="headerBg"
            align="center"
            justifyContent="center"
            gap={12}
            p={16}
            direction={{ base: 'column', md: 'row' }}
        >
            <MenterInfoCard
                imageSrc={wallet}
                heading="84%"
                text="Of Mentees Reported Saving Time or Money After a Session"
            />
            {!isMobile && <Divider orientation="vertical" height="60" />}
            <MenterInfoCard
                isSingleLine
                imageSrc={sessionsBooked}
                heading="30,000+"
                text="Mentorship Sessions Booked"
            />

            {!isMobile && <Divider orientation="vertical" height="60" />}
            <MenterInfoCard
                imageSrc={mentees}
                heading="78%"
                text="Of Mentees Reported a Confidence Boost After a Session"
            />
        </Flex>
    );
};
